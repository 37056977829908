<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :target_page="targetPage"
            :totalCount="totalCount"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :criteriaProps="criteriaProps"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    const api_root = 'offices';

    import ChirpList from '@/components/general/list/ChirpList';

    export default {
        name: 'OfficeDetail',
        components: { ChirpList },
        data() {
            return {
                rows: [],
                totalCount: 0,
                targetPage: 'OfficeDetail',
                loading: true,
                loadingTable: false,
                criteriaProps: {},
            };
        },
        methods: {
            async load_data() {
                const body = {
                    criteria: this.criteriaProps,
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows;
                this.criteriaProps = {
                    filters: this.pageMeta.filters,
                };
                this.totalCount = res.data.total_count;
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.$route.params && this.$route.params.criteria_props) {
                    this.criteriaProps = this.$route.params.criteria_props;
                }
                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria,
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
        },
        async created() {
            await this.init();
        },
    };
</script>
